import Web3 from "web3";
import NFTAbi from "@/assets/abi/NFTABI";
import ERC20Abi from "@/assets/abi/ERC20ABI";
import idoAbi from "@/assets/abi/IDOAbi";
import BigNumber from "bignumber.js";

let test = false;

let web3;
let wallet;

// let nftAddress = "0x4cF30Dd5Fc939e20b53ce322E9a7725116f58b8f";
let nftContract;

// let pollenToken = "0xc118D77bAf86a93EC41d867675c48C98B19953Fd";
let pollenContract;

let idoContract;

let honeyContract;

function isNull(obj) {
    return obj == null || obj == undefined || obj == "undefined";
}

function nftAddress() {
    if (test) {
        return "0x";
    }
    return "0x4cF30Dd5Fc939e20b53ce322E9a7725116f58b8f";
}

function pollenToken() {
    if (test) {
        return "0x3c228eD1703E76BdaBda0248dBD649BbB40af7De";
    }
    return "0xc118D77bAf86a93EC41d867675c48C98B19953Fd";
}

function idoAddress() {
    if (test) {
        return "0x3A1B6ac699e96a4Fd77A7Cb574ecb0bd67F15cE3";
    }
    return "0x6239CAe039eDbB1c562aB1FC90936682A376B615";
}

function honeyToken() {
    if (test) {
        return "0x6886a4C306AeFEeD51f6927349E50D363A6a5e5f";
    }
    return "0x641686ee9092484CCe91b334cf0681f51c3bDaDF";
}

function loadNftContract() {
    if (nftContract) {
        return nftContract;
    }
    nftContract = new web3.eth.Contract(NFTAbi, nftAddress());
    return nftContract;
}
function loadPollenContract() {
    if (pollenContract) {
        return pollenContract;
    }
    pollenContract = new web3.eth.Contract(ERC20Abi, pollenToken());
    return pollenContract;
}
function loadIdoContract() {
    if (idoContract) {
        return idoContract;
    }
    idoContract = new web3.eth.Contract(idoAbi, idoAddress());
    return idoContract;
}
function loadHoneyContract() {
    if (honeyContract) {
        return honeyContract;
    }
    honeyContract = new web3.eth.Contract(ERC20Abi, honeyToken());
    return honeyContract;
}
function loadWeb3() {
    if (web3) {
        return web3;
    }
    try {
        web3 = new Web3(window.ethereum);
    } catch {
        web3 = null;
    }
    return web3;
}

async function loadWallet() {
    try {
        await loadWeb3();
        let wallets = await web3.eth.requestAccounts();
        wallet = wallets[0];
    } catch (e) {
        console.log(e)
    }
    return wallet;
}

function toWei(val, unit) {
    return web3.utils.toWei(val, unit);
}

function fromWei(val, unit) {
    if (!web3) {
        return "0";
    }
    let value = web3.utils.fromWei(val, unit);
    let bnValue = new BigNumber(value);
    value = bnValue.toFixed(2);
    return value;
}

function addWalletListener(callBack) {
    window.ethereum.on('accountsChanged', function (accounts) {
        if (accounts[0] != wallet) {
            wallet = accounts[0];
            callBack?.call();
        }
    });
}



function calculateDiffTime(timeDiff){
    let hour = Math.floor(timeDiff / 3600);
    timeDiff = timeDiff % 3600;
    let minute = Math.floor(timeDiff / 60);
    timeDiff = timeDiff % 60;
    let second = timeDiff;
    return [hour, minute, second]

}

export const Utils = {
    test() {
        return test;
    },
    isNull(obj) {
        return isNull(obj);
    },
    web3() {
        return loadWeb3();
    },
    loadWallet() {
        return loadWallet();
    },
    wallet() {
        return wallet;
    },
    addWalletListener(callBack) {
        return addWalletListener(callBack);
    },
    loadNftContract() {
        return loadNftContract();
    },
    loadPollenContract() {
        return loadPollenContract();
    },
    loadIdoContract() {
    return loadIdoContract();
},
    loadHoneyContract() {
    return loadHoneyContract();
},
    nftAddress() {
        return nftAddress();
    },
    pollenAddress() {
        return pollenToken();
    },
    idoAddress() {
        return idoAddress();
    },
    toWei(val, unit) {
        return toWei(val, unit);
    },
    fromWei(val, unit) {
        return fromWei(val, unit);
    },
    calculateDiffTime(seconds) {
        return calculateDiffTime(seconds);
    }

}