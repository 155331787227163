<template>
<div>
  <div class="name-column name-column-topCenter">
    <div v-if="total" style="margin-top: 10px;">
      <div class="button-active" @click="stakeAll">Stake All</div>
      <div style="height: 5px"></div>
      <div class="tips-d" style="color: #DDDDDD">One stake all click max num is 20</div>
    </div>
    <div class="name-row container">
<!--      .slice((current_page-1)*9, current_page*9)-->
      <div class="card" v-for="(item, index) in nftList" :key="index">
        <el-image :src="item.image" fit="scale-down" style="width: 100%; height: auto; border-top-left-radius: 5px; border-top-right-radius: 5px;"/>
        <p class="tips">TokenID: {{item.tokenId}}</p>

        <p class="tips">Name: {{item.name}}</p>
        <div style="height: 10px"></div>
        <div class="name-row name-flex-center">
          <div class="button-active" @click="stake(item)">Stake</div>
        </div>
        <div style="height: 20px"></div>
      </div>
    </div>
  </div>
  <el-pagination background
      :hide-on-single-page="hide_on_single_page"
      :total="total"
      :page-size="9"
      :current-page="current_page"
      @current-change="pageChanged"
      layout="prev, pager, next">
  </el-pagination>
  <div style="height: 20px"></div>
</div>
</template>

<script>
import {Utils} from "@/utils/Utils";
import BigNumber from 'bignumber.js'
export default {
  name: "MyNft",
  data() {
    return {
      hide_on_single_page: true,
      current_page: 1,
      total: 0,
      nftList: [
          // {image:"https://files.catbox.moe/nk0prz.png",name: "flower",tokenId: 0},
      ],
      timeId: null,
    }
  },
  mounted() {
    let self = this;
    this.timeId = setInterval(function () {
      self.loadNftDetails();
    }, 3000);
    this.loadNftDetails();
  },
  beforeDestroy() {
    if (this.timeId) {
      clearInterval(this.timeId);
    }
  },
  methods: {
    async stakeAll() {
      try {
        let wallet = Utils.wallet();
        if (Utils.isNull(wallet)) {
          return
        }
        let contract = Utils.loadNftContract();
        let total = await contract.methods.nftListLength().call({
          from: wallet,
        });
        if (total == 0) {
          return;
        }
        let ids = await contract.methods.canStakeIDs(20).call({
          from: wallet,
        });
        if (ids.length > 20) {
          ids = ids.slice(0, 20);
        }
        let raleIds = [];
        for (let i = 0; i < ids.length; i++) {
          let _id = ids[i];
          if (_id != 0) {
            raleIds.push(_id);
          }
        }

        if (raleIds.length == 0) {
          return;
        }
        let result = await contract.methods.stakeAll(raleIds).send({
          from: wallet,
        });
        if (result.status == true) {
          this.$message.success("Stake Success");
          this.loadNftDetails().then();
        } else {
          this.$message.success("Stake Fail");
        }
      } catch (e) {
        this.$message.error(e.toString());
      }
    },
    pageChanged(index) {
      this.current_page = index;
      this.loadNftDetails();
    },
    async loadNftDetails() {
      let wallet = Utils.wallet();
      if (Utils.isNull(wallet)) {
        return
      }

      let contract = Utils.loadNftContract();
      let total = await contract.methods.nftListLength().call({
        from: wallet,
      });

      this.total = Number(total);
      if (total > 0) {
        let page = this.current_page;
        let start = (page-1)*9;
        let end = page*9-1;
        let nftList = await contract.methods.nftListByOffset(start, end).call({
          from: wallet,
        });
        this.nftList = nftList;
      } else {
        this.nftList = [];
      }
    },
    async stake(nftInfo) {
      let max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      try {
        let wallet = Utils.wallet();
        if (Utils.isNull(wallet)) {
          return
        }
        let contract = Utils.loadNftContract();
        let tokenContract = Utils.loadPollenContract();
        let allowanceValue = await tokenContract.methods.allowance(wallet, Utils.nftAddress()).call({
          from: wallet,
        });
        let allowance1 = Utils.fromWei(allowanceValue+"", "ether");
        let allowanceBN = new BigNumber(allowance1);
        let balance = await tokenContract.methods.balanceOf(wallet).call({
          from: wallet,
        });
        let balance1 = Utils.fromWei(balance+"", "ether");
        let balanceBN = new BigNumber(balance1);
        if (nftInfo.class == 1) {
          if (!balanceBN.gte("900")) {
            this.$message.error("Insufficient $Pollen balance");
            return
          }
          if (!allowanceBN.gte("900")){
            await tokenContract.methods.approve(Utils.nftAddress(), max).send({
              from: wallet,
            });
          }
        } else {
          if (!balanceBN.gte("300")) {
            this.$message.error("Insufficient $Pollen balance");
            return
          }
          if (!allowanceBN.gte("300")){
            await tokenContract.methods.approve(Utils.nftAddress(), max).send({
              from: wallet,
            });
          }
        }

        let isApproved = await contract.methods.isApprovedForAll(wallet, Utils.nftAddress()).call({
          from: wallet,
        });
        if (!isApproved) {
          await contract.methods.setApprovalForAll(Utils.nftAddress(), true).send({
            from: wallet
          });
        }

        let result = await contract.methods.stake(nftInfo.tokenId).send({
          from: wallet,
        });
        if (result.status == true) {
          this.$message.success("Stake Success");
          this.loadNftDetails().then();
        } else {
          this.$message.success("Stake Fail");
        }
      } catch (e) {
        this.$message.error(e);
      }
    },
  }
}
</script>

<style scoped>

/*.button-active {*/
/*  padding: 5px 10px;*/
/*  border-radius: 100px;*/
/*  border: 1px solid #DDDDDD;*/
/*  color: #DDDDDD;*/
/*  margin-bottom: 10px;*/
/*  margin-left: 5px;*/
/*  margin-right: 5px;*/
/*  background-color: rgba(33, 42, 33, 0.6);*/
/*}*/

.button-active {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #DDDDDD;
  color: #DDDDDD;
}

@media all and (max-width: 769px)  {
  .container {
    max-width: 90%;
    margin-top: 30px;
    /*background-color: red;*/
    -webkit-justify-content: center;
    -webkit-align-items: flex-start;
    justify-content: center;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .card {
    width: 300px;
    max-width: 100%;
    margin: 5px 5px 5px 5px;
    background-color: rgba(17, 17, 17, 1);
    border-radius: 5px;
  }
}
@media (min-width: 1201px)  {
  .container {
    max-width: 1000px;
    margin-top: 100px;
    /*-webkit-justify-content: center;*/
    -webkit-align-items: flex-start;
    /*justify-content: center;*/
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .card {
    width: 300px;
    margin: 5px 5px 5px 5px;
    background-color: rgba(17, 17, 17, 1);
    border-radius: 5px;
  }
}
.tips {
  color: white;
  font-size: 16px;
}
</style>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #67C23A;
}
.el-pagination.is-background .el-pager li.active {
  color: #67C23A;
}
.el-pagination.is-background .el-pager li:hover {
  color: #67C23A;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #67C23A;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #67C23A;
}

</style>