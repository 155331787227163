<template>
  <div>
    <div class="name-row name-flex-center">
      <div class="mint-container">
        <div style="height: 100px"></div>
        <div class="name-row name-flex-center">
          <div class="mint-button" style="background-color: transparent; height: 30px; width: 80%; padding: 0">
            <div style="height: 100%; width: 100%; position: relative; z-index: 7">
              <div style="position: absolute; background-color: rgba(159,36,26,1); height: 100%; top: 0; left: 0" :style="{width:((amountFix(balance)/10000)+'%')}"></div>
              <div class="name-column name-flex-center" style="position: absolute; width: 100%; height: 100%;">
                <div>
                  {{ configInfo ? amountFix(configInfo.honeyTotalNum) : 0 }}/1000000 $Honey
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 10px"></div>
        <div v-if="configInfo" style="font-size: 16px; font-weight: bolder; color: #DDDDDD" >
          "1 $Pollen" = {{configInfo.price}} $Honey
        </div>
        <div style="height: 30px"></div>
        <div class="name-row name-flex-center">
          <input style="
            width: 300px;
            height: 40px;
            text-align: center;
            color: #DDDDDD;
            font-size: 20px;
            font-weight: bolder;
            border: 1px solid #DDDDDD;"
                 v-model="num" @input="valueChanged"
                 type="number"
          />

        </div>
        <div style="height: 10px"></div>
        <div v-if="configInfo" style="font-size: 14px; color: #DDDDDD">
          min: {{amountFix(configInfo.minBuyLimit)}} | max: {{amountFix(configInfo.maxBuyLimit)}}
        </div>
        <div style="height: 10px"></div>
        <div v-if="configInfo" style="font-size: 18px; color: #DDDDDD">
          buy: {{amountFix(configInfo.pollenTokenNum)+"/"+amountFix(configInfo.maxBuyLimit)}}
        </div>
        <div v-if="configInfo && configInfo.openWhiteList">
          <div style="height: 10px"></div>
          <div style="font-size: 18px; color: red">
            {{configInfo.isWhiteList ? "this address is in whitelist" : "this address is not in whitelist"}}
          </div>
        </div>
        <div style="height: 30px"></div>

        <div v-if="configInfo" style="font-size: 18px; color: #DDDDDD">
          reward: {{ configInfo ? amountFix(configInfo.claimAmount)+"/"+amountFix(configInfo.buyAmount) : "" }}
        </div>
        <div style="height: 10px"></div>
        <div class="name-column name-flex-center">
          <div v-if="buttonClassFix" class="mint-button name-column" @click="depositTap">
            <div>
              {{buttonTitleFix}}
            </div>
          </div>
          <div v-if="!buttonClassFix" class="mint-button-disable name-column">
            <div>
              {{buttonTitleFix}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Utils} from "@/utils/Utils";
import BigNumber from "bignumber.js";

export default {
  name: "IDO",
  data() {
    return {
      wallet: null,
      balance: 0,
      num: 1,
      canClaim: null,
      configInfo: null,
    }
  },
  mounted() {
    let self = this;
    setInterval(function (){
      self.loadBalance();
    }, 3000);
    if (Utils.isNull(Utils.wallet())) {
      return;
    }
    self.loadBalance();
  },
  computed: {
    amountFix() {
      return (value) => {
        return Utils.fromWei(value+"", "ether");
      }
    },
    buttonTitleFix: function () {
      if (this.canClaim) {
        return "Withdraw";
      }
      return "Contribute";
    },
    buttonClassFix: function () {
      if (this.canClaim) {
        if (this.configInfo != null && this.configInfo.haveClaim) {
          return false;
        }
        return true;
      }
      if (this.configInfo != null && this.configInfo.canBuyNum == 0) {

        return false;
      }
      return true;
    }
  },
  methods: {
    valueChanged() {
      // if (this.num < 1) {
      //   this.num = 1;
      // } else if (this.num > 20) {
      //   this.num = 20;
      // }
    },
    handleChange(value) {
      if (value == "") {
        this.num = 1;
      }
      this.num = this.num+Number(value);
      if (this.num < 1) {
        this.num = 1;
      } else if (this.num > 20) {
        this.num = 20;
      }
    },
    async loadBalance() {
      try {
        if (Utils.isNull(Utils.wallet())) {
          return;
        }
        let idoContract = await Utils.loadIdoContract();
        this.configInfo = await idoContract.methods.configInfo().call({
          from: Utils.wallet()
        });
        this.canClaim = this.configInfo.canClaim;
        this.balance = this.configInfo.buyAmount;

      } catch (e) {
        console.log(e)
      }
    },
    async connect() {
      if (typeof window.ethereum === 'undefined') {
        this.$message.warning("MetaMask is uninstalled!");
        return
      }

      if (Utils.isNull(Utils.wallet())) {
        await Utils.loadWallet();
        this.wallet = Utils.wallet();
        return
      }
      this.wallet = Utils.wallet();
      this.loadBalance().then();
    },
    async depositTap() {
      if (this.canClaim) {
        await this.withdrawTap();
        return;
      }
      const loading = this.$loading({
        lock: true,
        // text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.3)'
      });
      try {
        if (Utils.isNull(Utils.wallet())) {
          await Utils.loadWallet();
          loading.close();
          return;
        }
        let value = Utils.toWei(this.num+"", "ether");

        let wallet = Utils.wallet();
        let tokenContract = Utils.loadPollenContract();

        let allowanceValue = await tokenContract.methods.allowance(wallet, Utils.idoAddress()).call({
          from: Utils.wallet(),
        });
        let allowance1 = Utils.fromWei(allowanceValue+"", "ether");
        let allowanceBN = new BigNumber(allowance1);
        let balance = await tokenContract.methods.balanceOf(wallet).call({
          from: wallet,
        });
        let balance1 = Utils.fromWei(balance+"", "ether");
        let balanceBN = new BigNumber(balance1);
        if (!balanceBN.gte((this.num)+"")) {
          this.$message.error("Insufficient $Pollen balance");
          loading.close();
          return
        }
        let max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
        if (!allowanceBN.gte((this.num)+"")){
          await tokenContract.methods.approve(Utils.idoAddress(), max).send({
            from: wallet,
          });
        }
        let idoContract = Utils.loadIdoContract();
        let result = await idoContract.methods.deposit(value).send({
          from: Utils.wallet(),
          // gasLimit: gasLimit,
        });
        if (result.status != true) {
          this.$message.error("deposit fail");
        }
        loading.close();
      } catch (e) {
        loading.close();
        this.$message.error(e);
      }
    },
    async withdrawTap() {
      const loading = this.$loading({
        lock: true,
        // text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.3)'
      });
      try {
        if (Utils.isNull(Utils.wallet())) {
          await Utils.loadWallet();
          loading.close();
          return;
        }

        let idoContract = Utils.loadIdoContract();
        let result = await idoContract.methods.claim().send({
          from: Utils.wallet(),
        });
        if (result.status != true) {
          this.$message.error("withdraw fail");
        }
        loading.close();
      } catch (e) {
        loading.close();

        this.$message.error(e);
      }
    },
    mintSuccess(nftInfo) {
      this.mintItem = nftInfo;
    }
  }
}
</script>

<style scoped>
.card {
  width: 300px;
  max-width: 100%;
  margin: 5px 5px 5px 5px;
  background-color: rgba(17, 17, 17, 1);
  border-radius: 5px;
}
.tips {
  color: white;
  font-size: 16px;
}
.mint-container {
  padding: 10px 10px;
  border: 10px solid  rgba(17, 17, 17, 0.6);
  max-width: 1000px;
  width: 80%;
  min-height: 200px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

@media all and (max-width: 769px) {
  .mint-button {
    padding: 10px 10px;
    border: 10px solid  rgba(17, 17, 17, 0.6);
    min-width: 200px;
    height: 40px;
    background-color: rgba(17, 17, 17, 0.6);
    color: #DDDDDD;
    font-size: 16px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 1201px) {
  .mint-button {
    padding: 10px 10px;
    border: 10px solid  rgba(17, 17, 17, 0.6);
    min-width: 200px;
    height: 40px;
    background-color: rgba(17, 17, 17, 0.6);
    color: #DDDDDD;
    font-size: 20px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}

.mint-button-disable {
  padding: 10px 10px;
  border: 10px solid  rgba(17, 17, 17, 0.3);
  min-width: 200px;
  height: 40px;
  background-color: rgba(17, 17, 17, 0.3);
  color: #999999;
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.button-active {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #DDDDDD;
  color: #DDDDDD;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgba(33, 42, 33, 0.6);
}

.button-active-selected {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #67C23A;
  color: #67C23A;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgba(33, 42, 33, 0.6);
}

.button-input {
  border-radius: 2px;
  border: 1px solid #DDDDDD;
  color: #DDDDDD;
  background-color: rgba(33, 42, 33, 0.6);
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /*z-index: -1;*/
}
</style>

<style>
input {
  background-color: transparent;
}
</style>