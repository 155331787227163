<template>
  <div>
    <div class="container">
      <el-image src="https://tokensimage.github.io/images/AVAX/Pollen/NFT/bg.jpg" style="width: 100%; height: 100vh;" fit="cover"/>
    </div>
    <div style="position: absolute; top: 0; left: 0; width: 100%; height: auto; margin-top: 30px">

      <div class="name-row name-row-centerRight name-flex-wrap-wrap">
<!--        <div class="button-active" @click="mint">Mint</div>-->
        <div :class="[buttonIndex == 0 ? 'button-active-selected' : 'button-active']" @click="toMyNft">My NFT</div>
        <div :class="[buttonIndex == 1 ? 'button-active-selected' : 'button-active']" @click="toMyStakeNft">My Stake NFT</div>
        <div :class="[buttonIndex == 2 ? 'button-active-selected' : 'button-active']" @click="toIdo">IDO</div>

<!--        <div class="button-active" >NFT Market</div>-->
<!--        <div class="button-active" >Buy Token</div>-->
        <div class="button-active" @click="connect">{{walletFix}}</div>
      </div>
      <div style="height: 20px"></div>
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
import {Utils} from "@/utils/Utils";
// import BigNumber from "bignumber.js";

export default {
  name: 'Servers',
  props: {
    // wallet: String
  },
  data() {
    return {
      wallet: null,
      buttonIndex: 0,
    }
  },
  mounted() {
    if (this.$router.currentRoute.path == "/myNft") {
      this.buttonIndex = 0;
    } else if (this.$router.currentRoute.path == "/stake") {
      this.buttonIndex = 1;
    } else if (this.$router.currentRoute.path == "/ido") {
      this.buttonIndex = 2;
    }
    if (window.ethereum && window.ethereum.isConnected) {
      this.connect();
      let self = this;
      Utils.addWalletListener(function () {
        self.wallet = Utils.wallet();
      });
    }

  },
  computed: {
    walletFix: function () {
      let wallet = this.wallet;
      if (Utils.isNull(wallet)) {
        return "Connect Wallet";
      }
      return wallet.substr(0, 4) + "..." + wallet.substr(wallet.length-4, wallet.length);
    }
  },
  methods: {

    toMyNft() {
      if (this.buttonIndex == 0) {
        return;
      }
      this.buttonIndex = 0;
      this.$router.push({path: "/myNft"});
    },
    toMyStakeNft() {
      if (this.buttonIndex == 1) {
        return;
      }
      this.buttonIndex = 1;
      this.$router.push({path: "/stake"});
    },
    toIdo() {
      if (this.buttonIndex == 2) {
        return;
      }
      this.buttonIndex = 2;
      this.$router.push({path: "/ido"});
    },
    mint() {
      this.$router.push({path: "/mint"});
    },
    async connect() {
      if (typeof window.ethereum === 'undefined') {
        this.$message.warning("MetaMask is uninstalled!");
        return
      }

      if (Utils.isNull(Utils.wallet())) {
        await Utils.loadWallet();
        this.wallet = Utils.wallet();
        return
      }
      this.wallet = Utils.wallet();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  width: 300px;
  max-width: 100%;
  margin: 5px 5px 5px 5px;
  background-color: rgba(17, 17, 17, 1);
  border-radius: 5px;
}
.tips {
  color: white;
  font-size: 16px;
}
.mint-container {
  padding: 10px 10px;
  border: 10px solid  rgba(17, 17, 17, 0.6);
  max-width: 1000px;
  width: 80%;
  min-height: 200px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.mint-button {
  padding: 10px 10px;
  border: 10px solid  rgba(17, 17, 17, 0.6);
  width: 200px;
  height: 40px;
  background-color: rgba(17, 17, 17, 0.6);
  color: #DDDDDD;
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.button-active {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #DDDDDD;
  color: #DDDDDD;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgba(33, 42, 33, 0.6);
}

.button-active-selected {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #67C23A;
  color: #67C23A;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgba(33, 42, 33, 0.6);
}

.button-input {
  border-radius: 2px;
  border: 1px solid #DDDDDD;
  color: #DDDDDD;
  background-color: rgba(33, 42, 33, 0.6);
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /*z-index: -1;*/
}
</style>
<style>
input {
  background-color: transparent;
}
</style>
