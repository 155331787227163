<template>
  <div id="app">
    <Servers/>
  </div>
</template>

<script>
import Servers from './components/Servers.vue'

export default {
  name: 'App',
  components: {
    Servers
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
body,h1,h2,ol,ul,dl,dd,p,textarea,input,select,option,form {
  margin:0; padding:0;
  font-size: 14px;
}
ul,ol {
  list-style-type:none;
}
</style>
