import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";

import './assets/css/name.css'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueRouter)
Vue.use(Element)

Vue.config.productionTip = false

/***router -> start****/
// import Mint from "@/components/Mint";
import Stake from "@/components/Stake";
import MyNft from "@/components/MyNft";
// import Servers from "@/components/Servers";
import NftContainer from "@/components/NftContainer";
import IDO from "@/components/IDO";
/***router -> end  ***/

const routes = [
  // {path: '/mint', component: Mint},

  {path: "/nftContainer",
    component: NftContainer,
    children: [
      // {path: "/servers", component: Servers},
      {path: "/myNft", component: MyNft},
      {path: '/stake', component: Stake},

    ],
  },
  {path: "/ido", component: IDO},
  {path: "/", redirect: "/nftContainer"}

]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from,next) => {
  if (to.path == from.path) {
    return
  }
  next();
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
