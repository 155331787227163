<template>
  <div>
    <div class="name-row name-flex-center" v-if="mintItemList.length" style="position: absolute; top: 0px; left: 0; width: 100%; height: auto; background-color: rgba(0,0,0,0.3);z-index: 999">
      <div style="padding: 10px 10px; border: 1px solid #DDDDDD; border-radius: 6px; background-color: rgba(0,0,0,0.6); position: relative">
        <div class="name-row name-row-centerRight">
          <div class="name-column name-flex-center" @click="mintItemList = []" style="border-radius: 26px; width: 26px; height: 26px; border: 1px solid #DDDDDD">
            <div class="el-icon-close" style="color: #DDDDDD;"></div>
          </div>
        </div>
        <div class="name-row name-flex-wrap-wrap name-flex-center">
          <div v-for="item in mintItemList" :key="item.tokenId" class="card"  style="position:relative;">
            <el-image :src="item.image" fit="scale-down" style="width: 100%; height: auto; border-top-left-radius: 5px; border-top-right-radius: 5px;"/>
            <p class="tips">TokenID: {{item.tokenId}}</p>
            <p class="tips">Name: {{item.name}}</p>
            <div style="height: 20px"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="name-row name-flex-center">
      <div class="mint-container">
        <div style="height: 10px"></div>
        <div class="name-row name-flex-center">
          <div class="mint-button" style="background-color: transparent; height: 30px; width: 80%; padding: 0">
            <div style="height: 100%; width: 100%; position: relative; z-index: 7">
              <div style="position: absolute; background-color: rgba(159,36,26,1); height: 100%; top: 0; left: 0" :style="{width:((balance/50)+'%')}"></div>
              <div style="position: absolute; width: 100%">{{ balance }}/5000</div>
            </div>
          </div>
        </div>
        <div style="height: 10px"></div>
        <div style="font-size: 16px; font-weight: bolder; color: #DDDDDD" >
          Price: {{ balance >= 5000 ? "500 $Pollen" : "0.8 AVAX" }}
        </div>
        <div style="height: 30px"></div>
        <div class="name-row name-flex-center">
          <div @click="handleChange(-1)" class="name-row name-flex-center button-input" style="width: 40px; height: 40px; margin-right: 5px;">
            <div class="el-icon-minus"></div>
          </div>
          <input style="
            width: 100px;
            height: 40px;
            text-align: center;
            color: #DDDDDD;
            font-size: 20px;
            font-weight: bolder;
            border: 1px solid #DDDDDD;"
                 v-model="num" @input="valueChanged"
                 type="number"
          />
          <div @click="handleChange(1)" class="name-row name-flex-center button-input" style="width: 40px; height: 40px; margin-left: 5px;">
            <div class="el-icon-plus"></div>
          </div>

        </div>
        <div style="height: 30px"></div>
        <div class="name-row name-flex-center">
          <div class="mint-button" @click="mintTap">Mint</div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {Utils} from "@/utils/Utils";
import BigNumber from "bignumber.js";

export default {
  name: "NftContainer",
  data() {
    return {
      wallet: null,
      nftName: "Success",
      nftImage: null,
      dialogVisible: false,
      balance: 0,
      mintItem: null,
      mintItemList: [],
      num: 1,
    }
  },
  mounted() {
    let self = this;
    setInterval(function (){
      self.loadBalance();
    }, 3000);
    if (Utils.isNull(Utils.wallet())) {
      return;
    }
    self.loadBalance();
  },
  methods: {
    valueChanged() {
      if (this.num < 1) {
        this.num = 1;
      } else if (this.num > 20) {
        this.num = 20;
      }
    },
    handleChange(value) {
      if (value == "") {
        this.num = 1;
      }
      this.num = this.num+Number(value);
      if (this.num < 1) {
        this.num = 1;
      } else if (this.num > 20) {
        this.num = 20;
      }
    },
    async loadBalance() {
      try {
        if (Utils.isNull(Utils.wallet())) {
          return;
        }
        let nftContract = await Utils.loadNftContract();
        this.balance = await nftContract.methods.nftAllLength().call({
          from: Utils.wallet()
        });
      } catch (e) {
        console.log(e)
      }
    },
    async connect() {
      if (typeof window.ethereum === 'undefined') {
        this.$message.warning("MetaMask is uninstalled!");
        return
      }

      if (Utils.isNull(Utils.wallet())) {
        await Utils.loadWallet();
        this.wallet = Utils.wallet();
        return
      }
      this.wallet = Utils.wallet();
      this.loadBalance().then();
    },
    async mintTap() {
      const loading = this.$loading({
        lock: true,
        // text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.3)'
      });
      try {
        if (Utils.isNull(Utils.wallet())) {
          await Utils.loadWallet();
          loading.close();
          return;
        }
        let value = Utils.toWei((this.num*8/10)+"", "ether");
        let nftContract = await Utils.loadNftContract();
        let balance = await nftContract.methods.nftAllLength().call({
          from: Utils.wallet()
        });
        if (balance >= 5000) {
          value = "0";
          let wallet = Utils.wallet();
          let tokenContract = Utils.loadPollenContract();
          let allowanceValue = await tokenContract.methods.allowance(wallet, Utils.nftAddress()).call({
            from: Utils.wallet(),
          });
          let allowance1 = Utils.fromWei(allowanceValue+"", "ether");
          let allowanceBN = new BigNumber(allowance1);
          let balance = await tokenContract.methods.balanceOf(wallet).call({
            from: wallet,
          });
          let balance1 = Utils.fromWei(balance+"", "ether");
          let balanceBN = new BigNumber(balance1);
          if (!balanceBN.gte((500*this.num)+"")) {
            this.$message.error("Insufficient $Pollen balance");
            loading.close();
            return
          }
          let max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
          if (!allowanceBN.gte((500*this.num)+"")){
            await tokenContract.methods.approve(Utils.nftAddress(), max).send({
              from: wallet,
            });
          }
        }
        let result = await nftContract.methods.safeMint(this.num).send({
          from: Utils.wallet(),
          value: value,
        });
        if (result.status == true) {
          let transfers = result.events.Transfer;
          if (Array.isArray(transfers)) {
            let tokendIds = [];
            for (let i = 0; i < transfers.length; i++) {
              let transferInfo = transfers[i];
              tokendIds[i] = transferInfo.returnValues.tokenId;
            }

            let nftInfos = await nftContract.methods.nftListByIds(tokendIds).call({
              from: Utils.wallet(),
            });
            // this.mintSuccess(nftInfo);
            this.mintItemList = nftInfos;
          } else {
            let values = transfers.returnValues;
            let tokenId = values.tokenId;

            let nftInfo = await nftContract.methods.nftDetails(tokenId).call({
              from: Utils.wallet(),
            });
            // this.mintSuccess(nftInfo);
            this.mintItemList = [nftInfo];
          }

        } else {
          this.$message.error("mint fail");
        }
        loading.close();
      } catch (e) {
        loading.close();

        this.$message.error(e);
      }
    },
    mintSuccess(nftInfo) {
      this.mintItem = nftInfo;

    }
  }
}
</script>

<style scoped>
.card {
  width: 300px;
  max-width: 100%;
  margin: 5px 5px 5px 5px;
  background-color: rgba(17, 17, 17, 1);
  border-radius: 5px;
}
.tips {
  color: white;
  font-size: 16px;
}
.mint-container {
  padding: 10px 10px;
  border: 10px solid  rgba(17, 17, 17, 0.6);
  max-width: 1000px;
  width: 80%;
  min-height: 200px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.mint-button {
  padding: 10px 10px;
  border: 10px solid  rgba(17, 17, 17, 0.6);
  width: 200px;
  height: 40px;
  background-color: rgba(17, 17, 17, 0.6);
  color: #DDDDDD;
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.button-active {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #DDDDDD;
  color: #DDDDDD;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgba(33, 42, 33, 0.6);
}

.button-active-selected {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #67C23A;
  color: #67C23A;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgba(33, 42, 33, 0.6);
}

.button-input {
  border-radius: 2px;
  border: 1px solid #DDDDDD;
  color: #DDDDDD;
  background-color: rgba(33, 42, 33, 0.6);
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /*z-index: -1;*/
}
</style>

<style>
input {
  background-color: transparent;
}
</style>