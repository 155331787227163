<template>
  <div>
    <div class="name-column name-column-topCenter">
      <div v-if="total" style="margin-top: 10px;">
        <div class="button-active" @click="claimAll">Claim All</div>
        <div style="height: 5px"></div>
        <div class="tips-d" style="color: #DDDDDD">One claim all click max num is 20</div>
      </div>
      <div class="name-row container" style="max-width: 1000px">
<!--        .slice((current_page-1)*9, current_page*9)-->
        <div style="position: relative" class="card" v-for="(item, index) in stakeList" :key="index">
          <el-image :src="item.image" fit="scale-down" style="width: 100%; height: auto; border-top-left-radius: 5px; border-top-right-radius: 5px;">
          </el-image>
          <div style="position: absolute; top: 0; right: 0; background-color: transparent">
            <el-popover
                placement="bottom"
                title=""
                width="210"
                trigger="click">
              <p>(1) The BEE card charges 20% tax.</p>
              <p>(2) The Flower card will get 0.1 AVAX reward every 24 hours.</p>
              <p>(3) The tax will be reduced by 5% every day and will be reset to 30% after the claim.</p>
              <p>(4) STAKE BEE card needs 900 pollen, stake Flower card needs 300 pollen.</p>
              <p>(5) Stake any card cannot be redeemed within 48 hours.</p>
              <div style="width: 40px; height: 40px" slot="reference" class="name-row name-flex-center">
                <i class="el-icon-warning-outline" style="color: #DDDDDD;"></i>
              </div>
            </el-popover>
          </div>
          <div class="text-cell name-row">
            <p class="tips-d">Reward of time: {{claimTime(item)}}</p>
          </div>
          <div class="text-cell name-row">
            <div class="name-flex-value" style="text-align: left">
              <p class="tips-d">TokenID: {{item.tokenId}}</p>
            </div>
            <div class="name-flex-value" style="text-align: left">
              <p class="tips-d">Pledge: {{pledge(item)}}</p>
            </div>
          </div>

          <div class="text-cell name-row">
            <div class="name-flex-value" style="text-align: left">
              <p class="tips-d">Available: {{availableAmount(item)}}</p>
            </div>
            <div class="name-flex-value" style="text-align: left">
              <p class="tips-d">Tax: {{(100-item.rewardRate)}}%</p>
            </div>
          </div>

          <p class="text-cell tips-d name-row" style="margin-right: 5px">
            Cancel of time: {{callBackTime(item)}}
          </p>
          <div style="height: 10px"></div>
          <div class="text-cell name-row">
           <div class="name-flex-value name-row">
             <div :class="[canClaim(item) ? 'button-active' : 'button-disable']" @click="claim(item)" >Withdraw</div>
           </div>
            <div class="name-flex-value name-row">
              <div :class="[canCallBack(item) ? 'button-active': 'button-disable']" @click="cancelStake(item)">Cancel Stake</div>
            </div>
          </div>
          <div style="height: 20px"></div>

        </div>

      </div>
    </div>
    <el-pagination background
        :hide-on-single-page="hide_on_single_page"
        :total="total"
        :page-size="9"
        :current-page="current_page"
        @current-change="pageChanged"
        layout="prev, pager, next">
    </el-pagination>
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import {Utils} from "@/utils/Utils";

export default {
  name: "Stake",
  data() {
    return {
      hide_on_single_page: true,
      current_page: 1,
      total: 0,
      stakeList: [
        // {image:"https://files.catbox.moe/nk0prz.png",name: "flower",tokenId: 0},
      ],
      timeId: null,
    }
  },
  mounted() {
    let self = this;
    this.timeId = setInterval(function () {
      self.loadStakeDetails();
    }, 3000);
    this.loadStakeDetails();
  },
  beforeDestroy() {
    if (this.timeId) {
      clearInterval(this.timeId);
    }
  },
  computed: {
    availableAmount() {
      return (stakeInfo) => {
        return Utils.fromWei(stakeInfo.availableAmount, "ether");
      }
    },
    rewardAmount() {
      return (stakeInfo) => {
        return Utils.fromWei(stakeInfo.rewardAmount, "ether");
      }
    },
    pledge() {
      return (stakeInfo) => {
        return Utils.fromWei(stakeInfo.pledge, "ether");
      }
    },
    callBackTime() {
      return (stakeInfo) => {
        let seconds = stakeInfo.startTime;
        let now = Math.floor(Date.now()/1000);
        let shouldTime = 48*60*60;
        if (Utils.test()) {
          shouldTime = 60*60;
        }
        if (shouldTime <= now-seconds) {
          return "00:00"
        }
        let times = Utils.calculateDiffTime(shouldTime-(now-seconds));
        if (times[0] > 0) {
          return (times[0]>9?times[0]:"0"+times[0])+":"+(times[1]>9?times[1]:"0"+times[1]);//+":"+(times[2]>9?times[2]:"0"+times[2]);
        }
        if (times[1] > 0) {
          return "00:"+(times[1]>9?times[1]:"0"+times[1]);//+":"+(times[2]>9?times[2]:"0"+times[2]);
        }
        if (times[2] > 0) {
          return "00:0";//+(times[2]>9?times[2]:"0"+times[2]);
        }
        return Utils.fromWei(stakeInfo.startTime, "ether");
      }
    },
    claimTime() {
      return (stakeInfo) => {
        let seconds = stakeInfo.claimTime;
        let now = Math.floor(Date.now()/1000);
        let shouldTime = 24*60*60;
        if (Utils.test()) {
          shouldTime = 10*60;
        }
        let time = shouldTime-((now-seconds)%shouldTime);
        if (time == 0) {
          return "00:00"
        }

        let times = Utils.calculateDiffTime(time);
        if (times[0] > 0) {
          return (times[0]>9?times[0]:"0"+times[0])+":"+(times[1]>9?times[1]:"0"+times[1]);
        }
        if (times[1] > 0) {
          return "00:"+(times[1]>9?times[1]:"0"+times[1]);
        }
        if (times[2] > 0) {
          return "00:0";//+(times[2]>9?times[2]:"0"+times[2]);
        }
        return Utils.fromWei(stakeInfo.startTime, "ether");
      }
    },
    canClaim() {
      return (stakeInfo) => {
        let seconds = stakeInfo.claimTime;
        let now = Math.floor(Date.now()/1000);
        let shouldTime = 24*60*60;
        if (Utils.test()) {
          shouldTime = 10*60;
        }
        if (shouldTime <= now-seconds) {
          return true;
        }
        return false;
      }
    },
    canCallBack() {
      return (stakeInfo) => {
        let seconds = stakeInfo.startTime;
        let now = Math.floor(Date.now()/1000);
        let shouldTime = 48*60*60;
        if (Utils.test()) {
          shouldTime = 60*60;
        }
        if (shouldTime <= now-seconds) {
          return true;
        }
        return false;
      }
    }
  },
  methods: {
    async claimAll() {
      try {
        let wallet = Utils.wallet();
        if (Utils.isNull(wallet)) {
          return
        }
        let contract = Utils.loadNftContract();
        let total = await contract.methods.stakeListLength().call({
          from: wallet,
        });
        let ids = await contract.methods.canCalimIds(0, total).call({
          from: wallet,
        });
        if (ids.length > 20) {
          ids = ids.slice(0, 20);
        }
        let raleIds = [];
        for (let i = 0; i < ids.length; i++) {
          let _id = ids[i];
          if (_id != 0) {
            raleIds.push(_id);
          }
        }
        if (raleIds.length == 0) {
          return;
        }
        let result = await contract.methods.claimForIds(raleIds).send({
          from: wallet,
        });
        if (result.status == true) {
          this.$message.success("Claim Success");
          this.loadStakeDetails().then();
        } else {
          this.$message.success("Claim Fail");
        }
      } catch (e) {
        this.$message.error(e);
      }
    },
    pageChanged(index) {
      this.current_page = index;
      this.loadStakeDetails();
    },
    async loadStakeDetails() {
      let wallet = Utils.wallet();
      if (Utils.isNull(wallet)) {
        return
      }
      let contract = Utils.loadNftContract();
      let total = await contract.methods.stakeListLength().call({
        from: wallet,
      });
      this.total = Number(total);
      if (total > 0) {
        let page = this.current_page;
        let stakeList = await contract.methods.stakeListOffset((page-1)*9, page*9-1).call({
          from: wallet,
        });
        this.stakeList = stakeList;
      } else {
        this.stakeList = [];
      }
    },
    async cancelStake(stakeInfo) {
      try {
        let wallet = Utils.wallet();
        if (Utils.isNull(wallet)) {
          return
        }
        let contract = Utils.loadNftContract();

        let result = await contract.methods.cancelStake(stakeInfo.tokenId).send({
          from: wallet,
        });
        if (result.status == true) {
          this.$message.success("cancelStake Success");
          this.loadStakeDetails().then();
        } else {
          this.$message.success("cancelStake Fail");
        }
      } catch (e) {
        this.$message.error(e);
      }
    },
    async claim(stakeInfo) {
      try {
        let wallet = Utils.wallet();
        if (Utils.isNull(wallet)) {
          return
        }
        let contract = Utils.loadNftContract();
        let result = await contract.methods.claim(stakeInfo.tokenId).send({
          from: wallet,
        });
        if (result.status == true) {
          this.$message.success("Withdraw Success");
          this.loadStakeDetails().then();
        } else {
          this.$message.success("Withdraw Fail");
        }
      } catch (e) {
        this.$message.error(e);
      }
    }
  }
}
</script>

<style scoped>
.button-active {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #DDDDDD;
  color: #DDDDDD;
}
.button-disable {
  padding: 5px 10px;
  border-radius: 100px;
  border: 1px solid #DDDDDD;
  color: #DDDDDD;
  opacity: 0.3;
  pointer-events: none;
}
@media all and (max-width: 769px)  {
  .container {
    max-width: 90%;
    margin-top: 30px;
    /*background-color: red;*/
    -webkit-justify-content: center;
    -webkit-align-items: flex-start;
    justify-content: center;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .card {
    width: 300px;
    max-width: 100%;
    margin: 5px 5px 5px 5px;
    background-color: rgba(17, 17, 17, 1);
    border-radius: 5px;
  }
}
@media (min-width: 1201px)  {
  .container {
    max-width: 1000px;
    margin-top: 100px;
    /*-webkit-justify-content: center;*/
    -webkit-align-items: flex-start;
    /*justify-content: center;*/
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .card {
    width: 300px;
    margin: 5px 5px 5px 5px;
    background-color: rgba(17, 17, 17, 1);
    border-radius: 5px;
  }
}
.tips {
  color: white;
  font-size: 16px;
}
.tips-d {
  color: #DDDDDD;
  font-size: 16px;
}
.text-cell {
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #67C23A;
}
.el-pagination.is-background .el-pager li.active {
  color: #67C23A;
}
.el-pagination.is-background .el-pager li:hover {
  color: #67C23A;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #67C23A;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #67C23A;
}
</style>